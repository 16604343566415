@font-face {
font-family: '__caveatBold_a6d185';
src: url(/_next/static/media/3b0fc195bc58bd4d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__caveatBold_Fallback_a6d185';src: local("Arial");ascent-override: 126.46%;descent-override: 39.52%;line-gap-override: 0.00%;size-adjust: 75.91%
}.__className_a6d185 {font-family: '__caveatBold_a6d185', '__caveatBold_Fallback_a6d185';font-weight: 700;font-style: normal
}.__variable_a6d185 {--font-caveat: '__caveatBold_a6d185', '__caveatBold_Fallback_a6d185'
}

@font-face {
font-family: '__sentinel_328fdf';
src: url(/_next/static/media/09142e63da99d9e5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sentinel_328fdf';
src: url(/_next/static/media/b327ec579b78a649-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__sentinel_328fdf';
src: url(/_next/static/media/85d1f56c9b64bde7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__sentinel_Fallback_328fdf';src: local("Arial");ascent-override: 100.91%;descent-override: 25.23%;line-gap-override: 0.00%;size-adjust: 95.13%
}.__className_328fdf {font-family: '__sentinel_328fdf', '__sentinel_Fallback_328fdf', serif
}.__variable_328fdf {--font-sentinel: '__sentinel_328fdf', '__sentinel_Fallback_328fdf', serif
}

@font-face {
font-family: '__objektivItalic_44e641';
src: url(/_next/static/media/9fabc056c122c39d-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__objektivItalic_Fallback_44e641';src: local("Arial");ascent-override: 98.86%;descent-override: 26.59%;line-gap-override: 0.00%;size-adjust: 114.70%
}.__className_44e641 {font-family: '__objektivItalic_44e641', '__objektivItalic_Fallback_44e641', sans-serif;font-style: italic
}.__variable_44e641 {--font-objektiv-italic: '__objektivItalic_44e641', '__objektivItalic_Fallback_44e641', sans-serif
}

@font-face {
font-family: '__objektiv_e891cc';
src: url(/_next/static/media/61df80ecee010bb9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__objektiv_e891cc';
src: url(/_next/static/media/c6b0634c92c04ed6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__objektiv_e891cc';
src: url(/_next/static/media/a0b32f996f188437-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__objektiv_Fallback_e891cc';src: local("Arial");ascent-override: 101.99%;descent-override: 27.43%;line-gap-override: 0.00%;size-adjust: 111.19%
}.__className_e891cc {font-family: '__objektiv_e891cc', '__objektiv_Fallback_e891cc', sans-serif
}.__variable_e891cc {--font-objektiv: '__objektiv_e891cc', '__objektiv_Fallback_e891cc', sans-serif
}

